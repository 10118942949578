<template>
  <div class="vg_wrapper" v-loading='isLoading'>
    <div class="vd_edhea">
     <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="pconForm"
        @openEdit="openEdit" 
        @closeEdit="closeEdit"
        @submit="submit('pconForm')"/> 
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
      <el-button type="primary" :disabled="isDisable" size="small" class="vd_exports" @click="takee()" v-if="isShow">生成出入库清单</el-button>
    </div>
    <el-form ref="pconForm" :rules="rules" :disabled="isShow" :model="pconForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="单据编号" prop="pcon_no">
            <el-input disabled v-model="pconForm.pcon_no" maxlength="30"  show-word-limit placeholder="请填写单据编号">
            </el-input>
          </el-form-item>
          <el-form-item label="交期时间" prop="pcon_date">
            <el-date-picker
              :disabled="purchase===1"
              v-model="pconForm.pcon_date"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购方" prop="acct_fctr_name">
            <el-input disabled v-model="pconForm.acct_fctr_name"  show-word-limit placeholder="请选择公司抬头"></el-input>
            <!-- <el-select disabled v-model="pconForm.acct_fctr_name" placeholder="请选择公司抬头" clearable>
              <el-option
                  v-for="item in acctForm"
                  :key="item.acct_fctr_id"
                  :label="item.acct_fctr_name"
                  :value="item.acct_fctr_name">
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="总金额" prop="pcon_total">
            <el-input :disabled="purchase===1" v-model="pconForm.pcon_total" maxlength="11" @input ="pconForm.pcon_total = helper.keepTNum1(pconForm.pcon_total)" @blur ="pconForm.pcon_total = helper.retain1(pconForm.pcon_total,2,100000000)" show-word-limit placeholder="请填写总金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="销售方" prop="tg_acct_fctr_name">
            <el-input disabled v-model="pconForm.tg_acct_fctr_name"  show-word-limit placeholder="请选择合作单位"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vd_mrt">
        <PodrAddProd :purchase="purchase" @cellClick="cellClick" @handleSelectionChange="handleSelect" :prodForm="pconForm"></PodrAddProd>
      </el-row>
      <el-tabs v-model="activeName" class="vd_eltab" v-if="flag" :disabled="isShow">
        <el-tab-pane label="BOM清单" name="first" :key="'first'">
          <PodrAddBomChild  v-loading="!refresh" :tableData="tableData"></PodrAddBomChild>
        </el-tab-pane>
        <el-tab-pane label="工艺步骤" name="second" :key="'second'">
          <div class="vd_but">
            <el-button type="primary" plain size="small" @click="addPrcs()" :disabled="empty">添加</el-button>
            <el-button type="danger" plain size="small" @click="delRouts()" :disabled="empty">删除</el-button>
            <span class="vd_ml10">
              <el-button type="success" plain size="small" @click="dropAble($event)" v-if="!drop" :disabled="empty">拖拽</el-button>
            </span>
            <span>
              <el-button type="info" plain size="small" @click="cancleDrop($event)" v-if="drop" >关闭拖拽</el-button>
            </span>
          </div>
          <PodrAddPrcsChild @selectionsList="selectionsLi" v-loading="!refresh" v-if="drag" :drop="drop" :empty="empty" @rowDrop="rowDrop" @prcsSelelct="prcsSelelct" :pconForm="pconForm" :selelct="selelct" :number="number" :options="options" :optionc="optionc" :isShow="isShow"></PodrAddPrcsChild>
        </el-tab-pane>
        <el-tab-pane label="出入库路线" name="third" :key="'third'">
          <PodrInOutWare v-loading="!refresh" :empty="empty" :acctForm="acctForm" :cpptForm="cpptForm" :cooperation="pconForm.cptt_id" :isShow="isShow" :pconForm="pconForm" :tableData1="tableData" :pconFormLi="pconFormLi" :number="number"></PodrInOutWare>
        </el-tab-pane>
      </el-tabs>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" ref="userData" :stffForm="stffForm" v-if="pconForm.stff_name"></inputUser>
        </el-col>
     </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {pconAPI} from "@api/modules/pcon";
import {acctAPI} from "@api/modules/acct";
import {cpttAPI} from "@api/modules/comptitle";
import {ppotAPI} from "@api/modules/ppot";
import editHeader from "@/views/component/editHeader";
import inputUser from "@/views/component/inputUser";
import PodrAddProd from "@/views/ContractManagement/PconManage/TabChild/componet/TabPanel/PconEditProd"
import PodrAddBomChild from "@/views/ContractManagement/PconManage/TabChild/componet/TabPanel/PconEditBomChild"
import PodrAddPrcsChild from "@/views/ContractManagement/PconManage/TabChild/componet/TabPanel/PconEditPrcsChild"
import PodrInOutWare from "@/views/ContractManagement/PconManage/TabChild/componet/TabPanel/PconInOutWare"

export default {
  name: "pconEditMain",
  components: {
    editHeader,
    inputUser,
    PodrAddProd,
    PodrAddBomChild,
    PodrAddPrcsChild,
    PodrInOutWare,
  },
  data() {
    return {
      rules:{
        pcon_total:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      isShow: true,
      pconForm:{},
      pconFormLi:[],
      smplTypeGroupOpt:[],
      smplEditIn:{},
      notice:false,
      exhibit: true,
      activeName: "first",
      tableData:[],
      tableData1:[],
      tableData2:{},
      pcon_prod_li:[],
      remove_pcon_prod_li:[],
      acctForm:[],
      cpptForm:[],
      bomList:[],
      prcsList:[],
      modrList:[],
      isDisable:true,
      flag:true,
      btn:{},
      show:null,
      bomPrcs:[],
      number:0,
      flags:[],
      selelct:[],
      options:[],
      optionc:[],
      sonOpt:[],
      addPrcsId:-1,
      purchase:0,
      empty:false,
      selectionsList:[],
      drop:false,
      drag:true,
      refresh:false,
      stffForm:{
        stff_name:'',
        cptt_name:'',
        dept_name:'',
        dept_team_name:'',
        stff_id:Number,
        cptt_id:Number,
        dept_id:Number,
        dept_team_id:Number,
      },
      isLoading:false
    }
  },
  watch: {
  },
  created() {
    this.initData()

  },
  methods:{
     initData(){
       this.getCppt()
       this.getHeader()
       this.getList()
       this.isLoading = true
     },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{

      })
    },
    // 保存
    saveInfo() {
      let pconForm1 = {};
      this.pconForm.pcon_date = (new Date(this.pconForm.pcon_date)).getTime() / 1000;
      pconForm1 = Object.assign(pconForm1,this.pconForm);
      if(pconForm1.pcon_prod_list.length>0){
        for(let i=0;i<this.pconFormLi.length;i++){
          let indexes = null;
          pconForm1.pcon_prod_list.forEach((item,index)=>{
              if(item.pcon_prod_id === this.pconFormLi[i].pcon_prod_id){
                indexes = index;
              }
          })
            if(pconForm1.pcon_prod_list[indexes].pcon_prod_rout_list !== null){
              let pconFormList = this.pconFormLi[i].pcon_prod_rout_list;
              for(let j=0;j<pconFormList.length;j++){
                let teep = false;
                for(let z=0;z<pconForm1.pcon_prod_list[indexes].pcon_prod_rout_list.length;z++){
                  if(pconForm1.pcon_prod_list[indexes].pcon_prod_rout_list[z].pcon_prod_rout_id){
                    if(pconForm1.pcon_prod_list[indexes].pcon_prod_rout_list[z].pcon_prod_rout_id == pconFormList[j].pcon_prod_rout_id){
                      teep = true;
                      break;
                    }
                  }
                }
                if(!teep){
                  this.pconFormLi[i].pcon_prod_rout_list[j].destroy_flag = 1;
                  this.pconFormLi[i].pcon_prod_rout_list[j].acct_id = null;
                  this.pconFormLi[i].pcon_prod_rout_list[j].tg_acct_id = null;
                  pconForm1.pcon_prod_list[indexes].pcon_prod_rout_list.push(this.pconFormLi[i].pcon_prod_rout_list[j]) ;
                }
              }
            }else{
              for(let j=0;j<this.pconFormLi[i].pcon_prod_rout_list.length;j++){
                this.pconFormLi[i].pcon_prod_rout_list[j].destroy_flag = 1;
              }
              pconForm1.pcon_prod_list[indexes].pcon_prod_rout_list = this.pconFormLi[i].pcon_prod_rout_list;
            }

            if(pconForm1.pcon_prod_list[indexes].pcon_prod_prcs_list !== null){
              let pconFormList = this.pconFormLi[i].pcon_prod_prcs_list;
              for(let j=0;j<pconFormList.length;j++){
                let teep = false;
                for(let z=0;z<pconForm1.pcon_prod_list[indexes].pcon_prod_prcs_list.length;z++){
                  pconForm1.pcon_prod_list[indexes].pcon_prod_prcs_list[z].prod_prcs_sort = z;
                  if(pconForm1.pcon_prod_list[indexes].pcon_prod_prcs_list[z].pcon_prod_prcs_id){
                    if(pconForm1.pcon_prod_list[indexes].pcon_prod_prcs_list[z].pcon_prod_prcs_id == pconFormList[j].pcon_prod_prcs_id){
                      teep = true;
                      break;
                    }
                  }
                }
                if(!teep){
                  this.pconFormLi[i].pcon_prod_prcs_list[j].destroy_flag = 1;
                  pconForm1.pcon_prod_list[indexes].pcon_prod_prcs_list.push(this.pconFormLi[i].pcon_prod_prcs_list[j]) ;
                }
              }
            }else{
              for(let j=0;j<this.pconFormLi[i].pcon_prod_prcs_list.length;j++){
                this.pconFormLi[i].pcon_prod_prcs_list[j].destroy_flag = 1;
              }
              pconForm1.pcon_prod_list[indexes].pcon_prod_prcs_list = this.pconFormLi[i].pcon_prod_prcs_list;
            }
          }
      }

      for(let i=0;i<pconForm1.pcon_prod_list.length;i++){
        if(JSON.stringify(pconForm1.pcon_prod_list[i].pcon_prod_rout_list) !== "{}"){
          for(let j=0;j<pconForm1.pcon_prod_list[i].pcon_prod_rout_list.length;j++){
            if(pconForm1.pcon_prod_list[i].pcon_prod_rout_list[j].pcon_prod_rout_date){
              pconForm1.pcon_prod_list[i].pcon_prod_rout_list[j].pcon_prod_rout_date = parseInt(pconForm1.pcon_prod_list[i].pcon_prod_rout_list[j].pcon_prod_rout_date)/1000;
            }
          }
        }else{
          pconForm1.pcon_prod_list[i].pcon_prod_rout_list = [];
        }
        if(JSON.stringify(pconForm1.pcon_prod_list[i].pcon_prod_prcs_list) !== "{}"){
          for(let a=0;a<pconForm1.pcon_prod_list[i].pcon_prod_prcs_list.length;a++){
            pconForm1.pcon_prod_list[i].pcon_prod_prcs_list[a].prod_prcs_sort = a;
          }
        }else{
          pconForm1.pcon_prod_list[i].pcon_prod_prcs_list = [];
        }
        
      }
      post(pconAPI.editPcon,pconForm1)
      .then(res => {                                                    
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          this.isShow = true;
          this.drop = false;
          this.flags = [];
          this.number = 0;
          this.selelct = [];
          this.refresh = false;
          this.$emit('isShow',this.isShow)
          this.initData()
        } else if(res.data.code === 7){
          this.$message({
            type:'error',
            message:'单据编号重复'
          })
          this.pconForm.pcon_date = (new Date(this.pconForm.pcon_date)).getTime() * 1000;
          for(let i=0;i<pconForm1.pcon_prod_list.length;i++){
            for(let j=0;j<pconForm1.pcon_prod_list[i].pcon_prod_rout_list.length;j++){
              if(pconForm1.pcon_prod_list[i].pcon_prod_rout_list[j].pcon_prod_rout_date){
                pconForm1.pcon_prod_list[i].pcon_prod_rout_list[j].pcon_prod_rout_date = parseInt(pconForm1.pcon_prod_list[i].pcon_prod_rout_list[j].pcon_prod_rout_date)*1000;
              }
            }
          }
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
          this.pconForm.pcon_date = (new Date(this.pconForm.pcon_date)).getTime() * 1000;
          this.isShow = true;
          this.drop = false;
          this.number = 0;
          this.flags = [];
          this.selelct = [];
          this.refresh = false;
          this.initData()
        }
      }).catch(res =>{
        let msg = res.data.msg 
        this.$message.error(msg)
      })
    },
    // 刷新按钮
    buttonRefresh(){
      this.initData()
    },
    // 生成按钮
    takee(){
       this.$confirm('是否生成?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          this.takeInfo()
          })
        .catch(()=>{
          this.$message({
            type:'info',
            message:'已取消生成'
          })
        })
      } 
    ,
    takeInfo(){
      let pcon_id = this.pconForm.pcon_id;
      post(ppotAPI.addPpotPndPpin,{pcon_id:pcon_id})
      .then(res => {                                                    
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'生成成功'
          })
        }else{
          let mg = res.data.msg;
          this.$message({
            type:'warning',
            message:mg
          })
        }
      }).catch(res =>{
        let msg = res.data.msg 
        this.$message.error(msg)
      })
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.drop = false;
        this.flags = [];
        this.selelct = [];
        this.refresh = false;
        this.initData()
        this.$emit('isShow',this.isShow)
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(res =>{
        let msg = res.data.msg 
        this.$message.error(msg)
      })
    },
    //启用编辑
    openEdit(){
      this.isShow = false;
      this.$emit('isShow',this.isShow)
    },
    // 单选表单表格确认值
    cellClick(val){
      this.drag = false;
      let ind = this.pconForm.pcon_prod_list.indexOf(val);
      this.number = ind;
      if(this.flags.indexOf(val.pcon_prod_id) === -1){
        this.refresh = false;
        this.getModr(val.pcon_prod_id,ind)
        this.getBom(val.pcon_prod_id,ind)
        this.getPrcs(val.pcon_prod_id,ind)
        this.flags.push(val.pcon_prod_id)
      }
      setTimeout(() => {
        this.tableData = this.pconForm.pcon_prod_list[ind].pcon_prod_mtrl_list;
        this.tableData1 = this.pconForm.pcon_prod_list[ind].pcon_prod_prcs_list;
        this.tableData2 = this.pconForm.pcon_prod_list[ind];
        this.addPrcsId = val.pcon_prod_id;
        for(let i=0;i<this.sonOpt.length;i++){
          if(val.pcon_prod_id === this.sonOpt[i].pconProdId){
            this.options = this.sonOpt[i].options;
            this.optionc = this.sonOpt[i].optionc;
          }
        }
      }, 2000);
      this.$nextTick(() => {
        this.drag = true;
      });
      // }
    },
    //删除选择的内容
    handleSelect(val){
      this.remove_pcon_prod_li = val;
    },
    // 更新select选择框
    prcsSelelct(val){
      this.selelct = this.deleteSelect(val)
    },
    selectionsLi(val){
      this.selectionsList = val;
    },
    // 删除选择内容
    delRouts(){
      for(let i = 0 ; i < this.selectionsList.length; i++) {
        this.tableData1.forEach((item,index)=>{
          if(item.prod_prcs_sort === this.selectionsList[i].prod_prcs_sort){
            this.tableData1.splice(index,1)
          }
        })
      }
      for (let a = 0;a < this.tableData1.length;a++){
        this.tableData1[a].prod_prcs_sort = a;
      }
    },
    // prcs步骤新增
    addPrcs() {
      this.drag = false;
      let item = {
        prod_prcs_sort: '',
        prod_prcs_name: '',
        prod_prcs_crft_names: '',
        prod_prcs_second:'',
        prod_prcs_part_in: '',
        prod_prcs_part_out: '',
        destroy_flag: 0,
      }
      item.prod_prcs_sort = this.tableData1.length;
      this.tableData1.push(item);
      for(let i=0;i<this.sonOpt.length;i++){
        if(this.addPrcsId === this.sonOpt[i].pconProdId){
          let temp = [];
          this.sonOpt[i].options[item.prod_prcs_sort] = temp;
          this.sonOpt[i].optionc[item.prod_prcs_sort] = temp;
          this.options = this.sonOpt[i].options;
          this.optionc = this.sonOpt[i].optionc;
        }
      }
      this.$nextTick(() => {
        this.drag = true;
      });
    },
    // prcs行拖拽
    dropAble(){
      this.drag = false;
      this.drop = true;
      this.empty = true;
      this.$nextTick(() => {
        this.drag = true;
      });
    },
    //取消拖拽
    cancleDrop(){
      this.drag = false;
      this.drop = false;
      this.empty = false;
      this.$nextTick(() => {
        this.drag = true;
      });
    },
    // 拖拽传值
    rowDrop(val){
      this.drag = false;
      let smpl_prcs_list1 = val.pcon_prod_list[this.number].pcon_prod_prcs_list;
      this.options = [];
      this.optionc = [];
      for(let i=0;i<smpl_prcs_list1.length;i++){
        let arr = [];
        let arr1 = [];
        arr = smpl_prcs_list1[i].prod_prcs_part_in.split(',');
        arr1 = smpl_prcs_list1[i].prod_prcs_part_out.split(',')
        this.options[i] = arr;
        this.optionc[i] = arr1;
        this.options[i] = this.options[i].filter(item => item && item.trim());
        this.optionc[i] = this.optionc[i].filter(item => item && item.trim())
      }
      this.$nextTick(() => {
        this.drag = true;
      });
    },
   
    // 获取公司抬头
    getHeader(){
      get(acctAPI.getAcctFctrs)
      .then(res=>{
        if (res.data.code === 0) {
          this.acctForm = res.data.data.list;
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取合作单位信息
    getCppt(){
      get(cpttAPI.getAllCpttsV1)
      .then(res=>{
        if (res.data.code === 0) {
          this.cpptForm = res.data.data;
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取物料
    getBom(num,num1){
      get(pconAPI.getPconProdMtrls,{pcon_prod_id:num})
      .then(res=>{
        if (res.data.code === 0) {
          if(res.data.data){
            this.bomList = res.data.data;
            if(this.bomList.length>0){
              this.pconForm.pcon_prod_list[num1].pcon_prod_mtrl_list = this.bomList;
              // this.pconFormLi[num1].pcon_prod_mtrl_list = this.bomList;
              this.bomList.forEach(item => {
                let temp = {};
                temp.value = item.mtrl_no;
                temp.label = item.mtrl_no;
                this.selelct.push(temp);
              });
              this.selelct = this.deleteSelect(this.selelct)
              this.refresh = true;
            }else{
              this.pconForm.pcon_prod_list[num1].pcon_prod_mtrl_list = [];
              this.pconFormLi[num1].pcon_prod_mtrl_list = [];
              this.refresh = true;
            }
          }
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取工艺步骤
    getPrcs(num,num1){
      get(pconAPI.getPconProdPrcss,{pcon_prod_id:num})
      .then(res=>{
        if (res.data.code === 0) {
           if(res.data.data){
            this.prcsList = res.data.data;
            if(this.prcsList.length>0){
              this.drag = false
              this.pconForm.pcon_prod_list[num1].pcon_prod_prcs_list = this.prcsList;
              let prcsList = this.helper.deepCopy(this.prcsList)
              this.pconFormLi[num1].pcon_prod_prcs_list = prcsList;
              let options = [];
              let optionc = [];
              this.prcsList.forEach(item=>{
                let str1 = item.prod_prcs_part_out.split(',');
                let str = item.prod_prcs_part_in.split(',');
                let arr = [];
                str1 = str1.filter(item=>item && item.trim())
                str = str.filter(item=>item && item.trim())
                if (item.prod_prcs_part_in !== ''){
                  let arr1 = [];
                  for(let i=0;i<str.length;i++){
                    if(arr1.indexOf(str[i]) === -1){
                      arr1.push(str[i])
                      let temp={};
                      temp.label = str[i];
                      temp.value = str[i];
                      this.selelct.push(temp)
                    }
                  }
                  options.push(arr1);
                }else{
                  options.push(arr);
                }
                if (item.prod_prcs_part_out !== ''){
                  let arr1 = [];
                  for(let i=0;i<str1.length;i++){
                    if(arr1.indexOf(str1[i]) === -1){
                      arr1.push(str1[i])
                      let temp={};
                      temp.label = str1[i];
                      temp.value = str1[i];
                      this.selelct.push(temp)
                    }
                  }
                  optionc.push(arr1);
                }else{
                  optionc.push(arr);
                }
              })
              let sonObj = {};
              sonObj.pconProdId = num;
              sonObj.options = options;
              sonObj.optionc = optionc;
              this.sonOpt.push(sonObj);
              this.selelct = this.deleteSelect(this.selelct)
              this.refresh = true;
              this.$nextTick(()=>{
                this.drag = true
              })
              
            }else{
              this.pconForm.pcon_prod_list[num1].pcon_prod_prcs_list = [];
              this.pconFormLi[num1].pcon_prod_prcs_list = [];
              this.refresh = true;
            }
          }
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 去除prcs选择框重复
    deleteSelect(select){
      let notHas = (arr,value) =>{
        let narr = arr.filter((item) =>{
          return item.value === value;
        });
        return narr.length == 0 ? true : false;
      };
      let newArr = [];
      for(let i=0;i<select.length;i++){
        if(notHas(newArr,select[i].value)){
          newArr.push(select[i]);
        }
      }
      return select = newArr;
    },
    // 获取出入库路线
    getModr(num,num1){
      get(pconAPI.getPconProdRouts,{pcon_prod_id:num})
      .then(res=>{
        if (res.data.code === 0) { 
          this.modrList = res.data.data;
          for(let i=0;i<this.modrList.length;i++){
            if(this.modrList[i].pcon_prod_rout_id){
              this.modrList[i].pcon_prod_rout_date=this.modrList[i].pcon_prod_rout_date*1000;
            }
          }
          if(this.modrList.length>0){
            this.pconForm.pcon_prod_list[num1].pcon_prod_rout_list = this.modrList;
            for(let i=0;i<this.modrList.length;i++){
              let temp = {};
              let temp1 = {};
              temp.cptt_id = this.modrList[i].cptt_id_to;
              temp.cptt_name = this.modrList[i].cptt_name_to;
              temp1.cptt_id = this.modrList[i].cptt_id_from;
              temp1.cptt_name = this.modrList[i].cptt_name_from; 
              this.cpptForm.push(temp);
              this.cpptForm.push(temp1);
            }
            this.cpptForm = this.deleteCptt(this.cpptForm);
            let modrList = this.helper.deepCopy(this.modrList)
            this.pconFormLi[num1].pcon_prod_rout_list = modrList;
            this.refresh = true;
          }else{
            this.pconForm.pcon_prod_list[num1].pcon_prod_rout_list = [];
            this.pconFormLi[num1].pcon_prod_rout_list = [];
            this.refresh = true;
          }
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 去除cpttForm重复
    deleteCptt(cpptForm){
      let notHas = (arr,value) =>{
        let narr = arr.filter((item) =>{
          return item.cptt_id === value;
        });
        return narr.length == 0 ? true : false;
      };
      let newArr = [];
      for(let i=0;i<cpptForm.length;i++){
        if(notHas(newArr,cpptForm[i].cptt_id)){
          newArr.push(cpptForm[i]);
        }
      }
      
      return cpptForm = newArr;
    },
    // 获取信息步骤
    getList(){
      let pcon_id = this.$route.query.form_id
      get(pconAPI.getPconById,{pcon_id:pcon_id})
      .then(res=>{
        if (res.data.code === 0) {
           // 权限按钮
          this.btn = res.data.data.btn;
          this.pconForm = res.data.data.form;
          let temp = {};
          temp.cptt_id = this.pconForm.cptt_id;
          temp.cptt_name = this.pconForm.cptt_name;
          this.cpptForm.push(temp);
          this.cpptForm = this.deleteCptt(this.cpptForm);
          if(this.pconForm.pcon_type === 0){
            this.purchase = 0;
          }else if(this.pconForm.pcon_type === 1){
            this.purchase = 1;
          }
          // 生成按钮状态
          if(this.pconForm.status === 2){
            this.isDisable = false;
          }
          this.getInputUser()
          this.pconForm.pcon_date = this.pconForm.pcon_date*1000;
          if(this.pconForm.pcon_prod_list.length>0){
            this.pconFormLi = this.helper.deepCopy(this.pconForm.pcon_prod_list);
            this.getJson()
            let num = this.pconForm.pcon_prod_list[0].pcon_prod_id;
            this.getBom(num,0)
            this.getPrcs(num,0)
            this.getModr(num,0)
            this.flags.push(num)
            setTimeout(()=>{
              this.tableData = this.pconForm.pcon_prod_list[0].pcon_prod_mtrl_list;
              this.tableData1 = this.pconForm.pcon_prod_list[0].pcon_prod_prcs_list;
              this.tableData2 = this.pconForm.pcon_prod_list[0];
              this.addPrcsId = num;
              for(let i=0;i<this.sonOpt.length;i++){
                if(num === this.sonOpt[i].pconProdId){
                  this.options = this.sonOpt[i].options;
                  this.optionc = this.sonOpt[i].optionc;
                }
              }
              this.refresh = true;
              this.isLoading = false  
            },500)
          }else{
            this.empty = true;
          }
        }
      })
      .catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    getJson(){
      for(let i=0;i<this.pconFormLi.length;i++){
        if(JSON.stringify(this.pconFormLi[i].podr_prod_mtrl_list) == "{}"){
          this.pconFormLi[i].podr_prod_mtrl_list=[]
        }
        if(JSON.stringify(this.pconFormLi[i].podr_prod_prcs_list) == "{}"){
          this.pconFormLi[i].podr_prod_prcs_list=[]
        }
        if(JSON.stringify(this.pconFormLi[i].podr_prod_rout_list) == "{}"){
          this.pconFormLi[i].podr_prod_rout_list=[]
        }
      }
    },
    //获取录入人
    getInputUser(){
      this.stffForm.cptt_id = this.pconForm.cptt_id;
      this.stffForm.user_id = this.pconForm.user_id;
      this.stffForm.dept_id = this.pconForm.dept_id;
      this.stffForm.stff_id = this.pconForm.stff_id;
      this.stffForm.dept_name = this.pconForm.dept_name;
      this.stffForm.dept_team_id = this.pconForm.dept_team_id;
      this.stffForm.dept_team_name = this.pconForm.dept_team_name;
      this.stffForm.stff_name = this.pconForm.stff_name;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_eltab {
  margin-top: 10px;
}
.vd_edhea{
  position: relative;
}
.vd_export{
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_but {
  margin-bottom: 10px;
}
.vd_ml10{
  margin-left:10px;
}
.vd_exports{
  position: absolute;
  top: 0;
  left: 120px;
}
</style>
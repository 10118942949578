<template>
  <div class="vg_wrapper">
    <div class="vd_but">
      <el-button type="primary" plain size="small" @click="addRouts()" :disabled="empty">添加</el-button>
      <el-button type="danger" plain size="small" @click="delRouts()" :disabled="empty">删除</el-button>
    </div>
    <!-- <el-form size="mini" :model="pconForm"> -->
    <el-table ref="multiTable" :data="pconForm.pcon_prod_list[number].pcon_prod_rout_list" v-if="pconForm.pcon_prod_list" @selection-change="handleSelectionChange" border>
      <el-table-column type="selection" width="48"  align="center"/>
      <el-table-column label="捆绑物料采购订单">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_rout_list.'+scope.$index+'.mcon_no'">
            <el-tooltip class="item" effect="dark" :content="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mcon_no" placement="top">
              <el-input disabled v-model="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mcon_no" maxlength="30" show-word-limit placeholder="暂无采购编号">
                <el-link slot="append" type="primary" @click="clickNum(scope)" :disabled="isShow" >捆绑</el-link>
              </el-input>
            </el-tooltip>
          </el-form-item>  
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">物料编号</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_rout_list.'+scope.$index+'.mtrl_no'" :rules="[{required:true}]">
            <el-select v-model="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mtrl_no" placeholder="请选择供应商" clearable  :formatter="formatName(scope)">
              <el-option
                  v-for="item in tableData1"
                  :key="item.mtrl_id"
                  :label="item.mtrl_no"
                  :value="item.mtrl_no">
              </el-option>
            </el-select>
          </el-form-item>  
        </template>
      </el-table-column>
      <el-table-column label="物料名称">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_rout_list.'+scope.$index+'.mtrl_name'">
            <el-input disabled v-model="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mtrl_name" show-word-limit placeholder="暂无物料名称"></el-input>
          </el-form-item>  
        </template>
      </el-table-column>
      <el-table-column label="物料单位">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_rout_list.'+scope.$index+'.mtrl_unit'">
            <el-input disabled v-model="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mtrl_unit" show-word-limit placeholder="暂无物料单位"></el-input>
          </el-form-item>  
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">物料数量</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_rout_list.'+scope.$index+'.mtrl_num'" :rules="[{required:true}]">
            <el-input @input ="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mtrl_num = helper.keepTNum1(pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mtrl_num)" @blur ="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mtrl_num = helper.retain(pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mtrl_num,0)" v-model="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].mtrl_num" show-word-limit placeholder="暂无物料数量"></el-input>
          </el-form-item>  
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">出库</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_rout_list.'+scope.$index+'.cptt_id_from'" :rules="[{required:true}]">
            <el-select v-model="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].cptt_id_from" placeholder="暂无出库" clearable>
              <el-option
                v-for="item in cpptForm"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select>
          </el-form-item>  
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">入库</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_rout_list.'+scope.$index+'.cptt_id_to'" :rules="[{required:true}]">
             <el-select @change="selectAddress(scope)"  v-model="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].cptt_id_to" placeholder="暂无入库" clearable>
              <el-option
                v-for="item in cpptForm"
                :key="item.cptt_id"
                :label="item.cptt_name"
                :value="item.cptt_id">
              </el-option>
            </el-select>
          </el-form-item>  
        </template>
      </el-table-column>
      <el-table-column label="收货地址">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_rout_list.'+scope.$index+'.cptt_address_to'">
            <el-input v-model="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].cptt_address_to" show-word-limit placeholder="暂无地址"></el-input>
          </el-form-item>  
        </template>
      </el-table-column>
      <el-table-column label="入库时间">
        <template slot="header">
          <span class="vg_deep_red">入库时间</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_rout_list.'+scope.$index+'.pcon_prod_rout_date'" :rules="[{required:true}]">
            <el-date-picker
              v-model="pconForm.pcon_prod_list[number].pcon_prod_rout_list[scope.$index].pcon_prod_rout_date"
              @change="setTime(scope)"
              type="date"
              placeholder="选择日期">
            />
            </el-date-picker>
            <!-- <el-input  v-model="pconForm[scope.$index].pcon_prod_rout_date" show-word-limit placeholder="暂无签订时间"></el-input> -->
          </el-form-item>  
        </template>
      </el-table-column>
    </el-table>
    <!-- </el-form> -->
    <el-dialog
      :title="title"
      :visible.sync="notice"
      width="70%">
      <SmplEditIn @confirmIn="confirmIn" ref="SmplEditIn"></SmplEditIn>
    </el-dialog>
  </div>
</template>
<script>
import SmplEditIn from "@/views/ContractManagement/PconManage/TabChild/componet/PconModrIn";

export default{
  props:{
    pconForm:{
      type:Object,
      required:true,
    },
    tableData1:{
      type:Array,
      required:true,
    },
    isShow:{
      type:Boolean,
      required:true,
    },
    number:{
      type:Number,
      required:true,
    },
    cooperation:{
      type:Number,
      require:true,
    },
    acctForm:{
      type:Array,
      required:true,
    },
    cpptForm:{
      type:Array,
      required:true,
    },
    empty:{
      type: Boolean,
      required:true,
    },
  },
  components:{
    SmplEditIn,
  },
  data(){
    return {
      notice:false,
      selelct:[],
      clickNumber:null,
      title:'',
    }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
     },
    // 导入选择确定
    confirmIn(val){
      let cpttAddressTo = this.cpptForm.filter(item=>{
          if(item.cptt_id === this.cooperation){
            return item.cptt_address
          }else{
            return ''
          }
        })
      this.smplEditIn = val;
      this.notice = false;
      this.pconForm.pcon_prod_list[this.number].pcon_prod_rout_list[this.clickNumber].mcon_id = this.smplEditIn.mcon_id;
      this.pconForm.pcon_prod_list[this.number].pcon_prod_rout_list[this.clickNumber].mcon_no = this.smplEditIn.mcon_no;
      this.pconForm.pcon_prod_list[this.number].pcon_prod_rout_list[this.clickNumber].cptt_id_from = this.smplEditIn.acct_fctr_id;
      this.pconForm.pcon_prod_list[this.number].pcon_prod_rout_list[this.clickNumber].cptt_id_to = cpttAddressTo.length>0? cpttAddressTo[0].cptt_id : this.pconForm[this.clickNumber].cptt_id_to;
      // this.pconForm[this.clickNumber].cptt_name_from = this.smplEditIn.acct_fctr_name;
      // this.pconForm[this.clickNumber].cptt_name_to = this.smplEditIn.cptt_name;
      this.pconForm.pcon_prod_list[this.number].pcon_prod_rout_list[this.clickNumber].cptt_address_to = cpttAddressTo.length>0? cpttAddressTo[0].cptt_address:this.pconForm[this.clickNumber].cptt_address_to;
      this.pconForm.pcon_prod_list[this.number].pcon_prod_rout_list[this.clickNumber].pcon_prod_rout_date = parseInt(this.smplEditIn.mcon_date)*1000;
      this.$refs.SmplEditIn.clear()
    },
    // 导入小窗口确定行
    clickNum(scope){
      this.notice = true;
      this.clickNumber = scope.$index;
    },
    // 添加出入库路线
    addRouts(){
      let cpttAddressTo = this.cpptForm.filter(item=>{
          if(item.cptt_id === this.cooperation){
            return item
          }else{
            return ''
          }
        })
      let item = {
        mcon_no: '',
        mtrl_no: '',
        mtrl_name: '',
        mtrl_unit:'',
        mtrl_num: '',
        cptt_id_from: '',
        cptt_id_to: cpttAddressTo.length>0? cpttAddressTo[0].cptt_id:'',
        // cptt_name_from: '',
        // cptt_name_to: cpttAddressTo.length>0? cpttAddressTo[0].cptt_name:'',
        cptt_address_to: cpttAddressTo.length>0? cpttAddressTo[0].cptt_address:'',
        pcon_prod_rout_date:'',
      }
      this.pconForm.pcon_prod_list[this.number].pcon_prod_rout_list.push(item)
    },
    // 多选
    handleSelectionChange(val){
      this.selelct = val;
    },
    // 转换时间按钮
    setTime(row){
      let routDate = row.row.pcon_prod_rout_date;
      routDate = (new Date(routDate)).getTime();
      row.row.pcon_prod_rout_date = routDate;
    },
    // 调整入库选择确定地址
    selectAddress(scope){
      let cpttAddressTo = this.cpptForm.filter(item=>{
        if(item.cptt_id === scope.row.cptt_id_to){
          return item.cptt_address
        }else{
          return ''
        }
      })
      scope.row.cptt_address_to =  cpttAddressTo.length>0? cpttAddressTo[0].cptt_address:'';
    },
    // 删除出入库路线
    delRouts(){
      for(let i=0;i<this.selelct.length;i++){
        let ind = this.pconForm.pcon_prod_list[this.number].pcon_prod_rout_list.indexOf(this.selelct[i]);
        if(ind != -1){
          this.pconForm.pcon_prod_list[this.number].pcon_prod_rout_list.splice(ind,1);
        }
      }
    },
    // 计算物料名称
    formatName(scope){
      this.tableData1.forEach(item=>{
        if(item.mtrl_no === scope.row.mtrl_no){
          scope.row.mtrl_name = item.mtrl_name;
          scope.row.mtrl_unit = item.mtrl_unit;
          scope.row.mtrl_id = item.mtrl_id;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.vd_but {
  margin-bottom: 10px;
}
</style>


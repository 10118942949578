<template>
  <div>
    <el-table highlight-current-row :max-height="tableHeight" ref="multiTable" @cell-click="cellClick" :data="prodForm.pcon_prod_list" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index+1 }}
        </template>
      </el-table-column>
      <el-table-column label="产品编号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+scope.$index+'.prod_no'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="prodForm.pcon_prod_list[scope.$index].prod_no" maxlength="10" show-word-limit placeholder="暂无产品编号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品名称">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+scope.$index+'.prod_name'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="prodForm.pcon_prod_list[scope.$index].prod_name" maxlength="30" show-word-limit placeholder="暂无产品名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品规格">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+scope.$index+'.prod_spec'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="prodForm.pcon_prod_list[scope.$index].prod_spec" maxlength="30" show-word-limit placeholder="暂无产品规格"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="产品类型">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+scope.$index+'.prod_type'" :rules="[{required:true}]">
            <!-- <el-input :disabled="true" v-model="prodForm.pcon_prod_list[scope.$index].prod_type" maxlength="30" show-word-limit placeholder="暂无物料名称"></el-input> -->
            <el-select disabled v-model="prodForm.pcon_prod_list[scope.$index].prod_type" placeholder="请选择产品类型" clearable>
              <el-option
                  v-for="item in prodTypeGroupOpt"
                  :key="item.id"
                  :label="item.param1"
                  :value="item.param1">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">数量</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+scope.$index+'.pcon_prod_num'" :rules="[{required:true}]">
            <el-input @change="totalAmount" :disabled="purchase===1" @input ="prodForm.pcon_prod_list[scope.$index].pcon_prod_num = helper.keepTNum1(prodForm.pcon_prod_list[scope.$index].pcon_prod_num)" @blur ="prodForm.pcon_prod_list[scope.$index].pcon_prod_num = helper.retain(prodForm.pcon_prod_list[scope.$index].pcon_prod_num,2)" show-word-limit maxlength="13" v-model="prodForm.pcon_prod_list[scope.$index].pcon_prod_num" placeholder="暂无数量"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">价格</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+scope.$index+'.pcon_prod_price'" :rules="[{required:true}]">
            <el-input @change="totalAmount" :disabled="purchase===1" @input ="prodForm.pcon_prod_list[scope.$index].pcon_prod_price = helper.keepTNum1(prodForm.pcon_prod_list[scope.$index].pcon_prod_price)" @blur ="prodForm.pcon_prod_list[scope.$index].pcon_prod_price = helper.retain(prodForm.pcon_prod_list[scope.$index].pcon_prod_price,2)" v-model="prodForm.pcon_prod_list[scope.$index].pcon_prod_price" maxlength="11" show-word-limit placeholder="暂无价格"></el-input>
            <!-- @input ="prodForm.pcon_prod_list[scope.$index].pcon_prod_price = helper.keepTNum1(prodForm.pcon_prod_list[scope.$index].pcon_prod_price)" -->
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {optnAPI} from "@api/modules/optn";

export default {
  name:"ProdAddProd",
  props:{
    prodForm:{
      type: Object,
      required:true,
    },
    purchase:{
      type: Number,
      required:true,
    }
  },
  data(){
    return {
      selectionsList:[],
      tableHeight:269,
      prodTypeGroupOpt:[],
    }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
      this.getSmplType()
    },
    // 获取玩具类型
    getSmplType(flag){
      get(optnAPI.getAllContent,{perm_id : 10003})
      .then(res=>{
        if(res.data.code === 0) {
          this.prodTypeGroupOpt = res.data.data;
          console.log()
        }
      })
    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
      this.multiSelection = null
    },
    //产品信息选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection
      this.$emit("handleSelectionChange",this.selectionsList)
    },
    // 单选信息格的时候
    cellClick(val){
      this.$emit("cellClick",val)
    },
    // 计算总金额
    totalAmount(){
      let temp = 0;
      if(this.prodForm.pcon_prod_list.length>0){
        for(let i=0;i<this.prodForm.pcon_prod_list.length;i++){
          if(this.prodForm.pcon_prod_list[i].pcon_prod_num!==undefined && this.prodForm.pcon_prod_list[i].pcon_prod_price!==undefined){
            temp = temp + (parseFloat(this.prodForm.pcon_prod_list[i].pcon_prod_num*100)/100)*(parseInt(this.prodForm.pcon_prod_list[i].pcon_prod_price*100)/100)
          }
        }
        this.prodForm.pcon_total = temp;
      }  
    }
  },
}
</script>

<style scoped>

</style>>

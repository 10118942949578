import topic from '../topic'
export const pconAPI = {
    getPcons: topic.pconTopic + '/get_pcons',
    getPconById: topic.pconTopic + '/get_pcon_by_id',
    getPconProdMtrls: topic.pconTopic + '/get_pcon_prod_mtrls',
    getPconProdPrcss: topic.pconTopic + '/get_pcon_prod_prcss',
    getPconProdRouts: topic.pconTopic + '/get_pcon_prod_routs',
    addPcon: topic.pconTopic + '/add_pcon',
    editPcon: topic.pconTopic + '/edit_pcon',
    deletePconByIds: topic.pconTopic + '/delete_pcon_by_ids'
}
<template>
  <div class="vg_wrapper" v-if="flag">
    <el-table :data="pconForm.pcon_prod_list[number].pcon_prod_prcs_list" v-if="pconForm.pcon_prod_list" border @selection-change="handleSelectionChange1"  class="el-table-two">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column width="80">
        <template slot="header">
          <span class="vg_deep_red">工艺顺序</span>
        </template>
        <template slot-scope="scope">
            {{pconForm.pcon_prod_list[number].pcon_prod_prcs_list[scope.$index].prod_prcs_sort+1}}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vg_deep_red">步骤名称</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_prcs_list.'+scope.$index+'.prod_prcs_name'"  :rules="[{required:true}]">
            <el-input v-model="pconForm.pcon_prod_list[number].pcon_prod_prcs_list[scope.$index].prod_prcs_name" placeholder="请填写步骤名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="工艺描述">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'pcon_prod_list.'+number+'.pcon_prod_prcs_list.'+scope.$index+'.prod_prcs_crft_names'">
              <el-tooltip class="item" effect="dark" :content="pconForm.pcon_prod_list[number].pcon_prod_prcs_list[scope.$index].prod_prcs_crft_names" placement="top" v-if="pconForm.pcon_prod_list[number].pcon_prod_prcs_list[scope.$index].prod_prcs_crft_names">
                <el-input v-model="pconForm.pcon_prod_list[number].pcon_prod_prcs_list[scope.$index].prod_prcs_crft_names" placeholder="请填写工艺名称">
                  <el-link slot="append" type="primary" class="vd_dis vd_mrl" @click="port(scope)" size="small" :disabled="isShow">导入</el-link>
                </el-input>
              </el-tooltip>
              <el-input v-model="pconForm.pcon_prod_list[number].pcon_prod_prcs_list[scope.$index].prod_prcs_crft_names" placeholder="请填写工艺名称" v-else>
                <el-link slot="append" type="primary" class="vd_dis vd_mrl" @click="port(scope)" size="small" :disabled="isShow">导入</el-link>
              </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="进料">
        <template slot-scope="scope" >
          <el-form-item label-width="0" class="vd_dis" :prop="'pcon_prod_list.'+number+'.pcon_prod_prcs_list.'+scope.$index+'.prod_prcs_part_in'">
            <el-select
              v-model="options[scope.$index]"
              ref="prcsSel"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="请选择进料名称"
              @change = change(scope,1)>
              <el-option
                v-for="item in selelct"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="出料">
        <template slot-scope="scope" >
          <el-form-item label-width="0" class="vd_dis" :prop="'pcon_prod_list.'+number+'.pcon_prod_prcs_list.'+scope.$index+'.prod_prcs_part_out'">
            <el-select
              v-model="optionc[scope.$index]"
              multiple
              filterable
              allow-create
              default-first-option
              placeholder="请选择出料名称"
              @change = change(scope,2)>
              <el-option
                v-for="item in selelct"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="70%">
      <PconPrcsIn @confirmIn="confirmIn" ref="PconPrcsIn"></PconPrcsIn>
    </el-dialog>
  </div>
</template>

<script>
import PconPrcsIn from "@/views/ContractManagement/PconManage/TabChild/componet/PconPrcsIn"
import Sortable from "sortablejs";

export default {
  name: "pconAddPrcsChild",
  components:{
    PconPrcsIn,
  },
  props:{
    pconForm:{
      type:Object,
      required:true,
    },
    selelct:{
      type:Array,
      required:true,
    },
    options:{
      type:Array,
      required:true,
    },
    optionc:{
      type:Array,
      required:true,
    },
    isShow:{
      type: Boolean,
      required:true,
    },
    empty:{
      type: Boolean,
      required:true,
    },
    drop:{
      type: Boolean,
      required:true,
    },
    number:{
      type: Number,
      required:true,
    },
  },
  data() {
    return {
      dialogVisible:false,
      prcsIndex:0,
      prcsList:[],
      flag:true,
      selectionsList:[],
      begin:false,
      title:'',
      tableData:[]
    }
  },
  created() {
    this.initData()
  },
  mounted() {
    this.rowDrop();
  },
  methods: {
    initData(){
    },
    // prcs导入按钮
    port(scope){
      this.dialogVisible = true;
      this.prcsIndex = scope.$index;
    },
    // prcs清单小窗口确定
    confirmIn(val) {
      this.prcsList = val;
      this.dialogVisible = false;
      this.flag = false;
      let temp = this.pconForm.pcon_prod_list[this.number].pcon_prod_prcs_list[this.prcsIndex].prod_prcs_crft_names;
      this.prcsList.forEach(item=>{
        temp = temp+','+item.crft_name;
      })
      temp = temp.replace(/^(\s|,)+|(\s|,)+$/g, '')
      this.pconForm.pcon_prod_list[this.number].pcon_prod_prcs_list[this.prcsIndex].prod_prcs_crft_names = temp;
      this.$refs.PconPrcsIn.clearSelect()
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    //prcs清单选择框
    handleSelectionChange1(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('selectionsList',this.selectionsList)
    },
    // prcs进料出料选择
    change(scope,num){
      if(num === 1){
        this.options[scope.$index] = this.options[scope.$index].filter(item => item && item.trim())
        let arr = this.options[scope.$index].filter(item => this.selelct.every(item1 => item != item1.value));
        for (let i=0;i<arr.length;i++){
          if(arr[i] !== ' '){
            let temp = {};
            temp.value = arr[i];
            temp.label = arr[i];
            this.selelct.push(temp);
          }
        }
        this.$emit('prcsSelelct',this.selelct)
        this.pconForm.pcon_prod_list[this.number].pcon_prod_prcs_list[scope.$index].prod_prcs_part_in = this.options[scope.$index].join(',');
        
      }else if(num === 2){
        this.optionc[scope.$index] = this.optionc[scope.$index].filter(item => item && item.trim())
        let arr = this.optionc[scope.$index].filter(item => this.selelct.every(item1 => item != item1.value));
        for (let i=0;i<arr.length;i++){
          if(arr[i] !== ' '){
            let temp = {};
            temp.value = arr[i];
            temp.label = arr[i];
            this.selelct.push(temp);
          }
        }
        this.$emit('prcsSelelct',this.selelct)
        this.pconForm.pcon_prod_list[this.number].pcon_prod_prcs_list[scope.$index].prod_prcs_part_out = this.optionc[scope.$index].join(',');
      }
    },
    //拖拽排序
    rowDrop() {
      
      if(this.drop){
        let tableData = this.pconForm.pcon_prod_list[this.number].pcon_prod_prcs_list;
        const tbody = document.querySelector('.el-table-two .el-table__body-wrapper table tbody')
        const _this = this
        this.sortable = Sortable.create(tbody, {
          draggable: ".el-table__row",
          onEnd({ newIndex, oldIndex }) {
            let currRow = tableData.splice(oldIndex, 1)[0]
            tableData.splice(newIndex, 0, currRow)
            _this.$emit('rowDrop', _this.pconForm)
          }
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>
<template>
  <div class="vg_wrapper">
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm"  @keyup.enter.native="getMtrlsNow()">
          <el-row>
            <el-col :md="7">
              <el-form-item label="工艺编号" >
                <el-input size="small" v-model.trim="searchForm.crft_no" clearable placeholder="请填写物料工艺编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="7" >
              <el-form-item label="工艺描述">
                <el-input size="small" v-model.trim="searchForm.crft_name" clearable placeholder="请填写工艺名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="10">
              <el-form-item label="工艺类型">
                <el-select size="small" v-model="searchForm.crft_type" placeholder="请选择工艺类型" clearable @visible-change="getCrftType($event)">
                  <el-option
                      v-for="item in smplTypeGroupCrft"
                      :key="item.value"
                      :label="item.param1"
                      :value="item.param1">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrlsNow()" class="vg_ml_16">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-row class="vd_mar15">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
        <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmPrcs()">导入</el-button>
      </span>
      </el-row>
      <el-row class="vd_mrt">
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" :row-key="getRowKey"  border v-loading="loading">
            <el-table-column  type="selection" width="48"  align="center" :reserve-selection="true"/>
            <el-table-column label="工艺编号" prop="crft_no"/>
            <el-table-column label="工艺类型" prop="crft_type"/>
            <el-table-column label="工艺描述" prop="crft_name" />
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch"></pubPagination>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {crftAPI} from "@api/modules/crft"
import pubPagination from "@/components/common/pubPagination";
import options from "@/views/component/common/options";
import {optnAPI} from "@api/modules/optn";

export default {
  name: "AcctList",
  components:{
    pubPagination
  },
  data() {
    return {
      tableData:[],
      searchForm:{
        crft_no: '',
        crft_name: '',
        crft_type:null,
      },
      totalPage:0,
      smplTypeGroupCrft: [],
      btn:{},
      loading: true,
      multiSelection: [],
      currentPage:1,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData(){
      this.getMtrlsList()
      this.smplTypeGroupCrft = options.crftOpts;
    },
    // 获取物料信息
    getMtrlsList(){
      get(crftAPI.getCrfts,{
        crft_no: this.searchForm.crft_no,
        crft_name: this.searchForm.crft_name,
        crft_type: this.searchForm.crft_type,
        page_no: this.currentPage,
      })
      .then(res=>{
        if(res.data.code === 0){
          this.loading = false;
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })

    },
    // 查询方法
    getMtrlsNow(){
      this.currentPage = 1
      this.getMtrlsList()
    },
    // 清空选择器
    clearSelect(){
      this.$refs.multiTable.clearSelection();
    },
    // 指定一个key标识这一行的数据
    getRowKey (row) {
      return row.crft_id;
    },
    // 多选
    handleSelectionChange(val){
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val;
      this.getMtrlsList()
    },
    // 获取工艺类型
    getCrftType(flag){
      get(optnAPI.getAllContent,{perm_id : 10002})
          .then(res=>{
            if(res.data.code === 0) {
              this.smplTypeGroupCrft = res.data.data
            }
          })
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDay(row.create_time)
    },
    // 取消选择
    cancel(){
      this.clearSelect()
    },
    // 确认选择
    confirmIn(){
      this.$emit("confirmIn",this.multiSelection)
    },
    // 选未选中提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_mar15{
  margin: 15px 0;
}
</style>

<template>
  <div class="vg_wrapper">
    <el-table ref="multiTable" :data="tableData"  border>
      <!-- <el-table-column type="selection" width="48"  align="center"/> -->
      <el-table-column label="物料编号" prop="mtrl_no" />
      <el-table-column label="物料名称" show-overflow-tooltip prop="mtrl_name" />
      <el-table-column label="类型" prop="mtrl_type" :formatter="formatLeavType" />
      <el-table-column label="规格" show-overflow-tooltip prop="mtrl_spec" />
      <el-table-column label="颜色" prop="mtrl_color" />
      <el-table-column label="克重" prop="mtrl_weight" />
      <el-table-column label="门幅" prop="mtrl_width" />
      <el-table-column label="单位" prop="mtrl_unit" />
      <el-table-column label="耗料" prop="prod_mtrl_consume" />
    </el-table>
  </div>
</template>

<script>

export default {
name: "podrAddBomChild",
  props:{
    tableData:{
      type:Array,
      required:true,
    }
  },
  data() {
    return {
      
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData(){
    },
    // 物料类型
    formatLeavType(row){
      if(row.mtrl_type === 1){
        return '面料'
      } else if(row.mtrl_type === 2){
        return '非面料'
      } 
    },
  }
}
</script>

<style scoped lang="scss">
</style>
<template>
    <div class="vd_search_group vg_mtb_16">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMconsNow()">
        <el-row>
          <el-col :md="7">
            <el-form-item label="合同编号：">
              <el-input size="small" v-model.trim="searchForm.mcon_no" clearable placeholder="请填写合同编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="7">
            <el-form-item label="账套工厂：">
              <el-select v-model="searchForm.acct_fctr_id" filterable placeholder="请选择账套工厂" clearable @visible-change="getAcctFctrId($event)" size="small">
                <el-option
                    v-for="item in acctFctrGroupOpt"
                    :key="item.acct_fctr_id"
                    :label="item.acct_fctr_name"
                    :value="item.acct_fctr_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="7">
            <el-form-item label="合作单位：">
              <el-select v-model="searchForm.cptt_id" filterable placeholder="请选择合作单位" clearable @visible-change="getCpttId($event)" size="small">
                <el-option
                    v-for="item in cpttGroupOpt"
                    :key="item.cptt_id"
                    :label="item.cptt_name"
                    :value="item.cptt_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="3">
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getMconsNow()" class="vg_ml_16">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="vd_mar15">
        <!-- <el-button size="mini" type="danger" @click="cancel">取消选择</el-button> -->
        <!-- <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button> -->
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" @row-dblclick="confirmIn" border>
            <!-- <el-table-column type="selection" width="48"  align="center"/> -->
              <el-table-column label="合同编号" prop="mcon_no" />
              <el-table-column label="账套工厂名" prop="acct_fctr_name" />
              <el-table-column label="合作单位名" prop="tg_acct_fctr_name" />
              <el-table-column label="交货时间" prop="mcon_date" :formatter="formatDate1" />
          </el-table>
        </el-col>
      </el-row>
      <el-row>
      <el-col :md="24">
        <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {mconAPI} from "@api/modules/mcon"
import pubPagination from "@/components/common/pubPagination";
import {cpttAPI} from "@api/modules/comptitle";
import {acctAPI} from "@api/modules/acct";

export default{
  name:"SmplEditIn",
  components:{
    pubPagination
  },
  props:{
  },
  data(){
    return{
      tableData:[],
      searchForm:{
        acct_fctr_id:null,
        cptt_id:null,
        mcon_no:'',
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        },
        timeValue1:{
          startTime:null,
          endTime:null
        }
      },
      totalPage:0,
      btn:{},
      loading: true,
      multiSelection: [],
      currentPage:1,
      flag:false,
      acctFctrGroupOpt:[],
      cpttGroupOpt:[],
    }
  },
  created(){
    this.initData()
  },
  methods:{
    initData(){
      this.getMconsList()
    },
    // 获取样品信息
    getMconsList(){
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      const timeNewVal1 = this.helper.getTime(this.searchForm.timeValue1)
      get(mconAPI.getMcons,
          {
            mcon_no: this.searchForm.mcon_no,
            acct_fctr_id: this.searchForm.acct_fctr_id,
            cptt_id: this.searchForm.cptt_id,
            status: this.searchForm.status,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            start_time2: timeNewVal1.startTime,
            end_time2: timeNewVal1.endTime,
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 获取账套工厂id
    getAcctFctrId(flag){
      if(flag === true && this.acctFctrGroupOpt.length === 0){
        get(acctAPI.getAcctFctrs)
            .then(res=>{
              if(res.data.code === 0){
                this.acctFctrGroupOpt = res.data.data.list
              }
            })
      }
    },
    // 获取合作单位id
    getCpttId(flag){
      if(flag === true && this.cpttGroupOpt.length === 0){
        get(cpttAPI.getAllCpttsV1,{page_no:1})
            .then(res=>{
              if(res.data.code === 0){
                this.cpttGroupOpt = res.data.data
                console.log('000',this.cpttGroupOpt)
              }
            })
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    formatDate1(row) {
      return this.helper.toStringDate(row.mcon_date)
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 查询方法
    getMconsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.getMconsList()
    },
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
    },
    // 取消选择
    cancel(){
      this.clear()
    },
    // 确认选择
    confirmIn(row){
      this.$emit("confirmIn",row)
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.getMconsList()
    },
  }
}
</script>

<style lang="scss" scoped>
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_mar15{
  margin: 15px 0;
}
</style>
